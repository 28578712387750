import React from 'react'
import Link from 'gatsby-link'
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../layouts/de"
import Block from "../components/Block"
import Phone from "../components/Phone"
import Chart from "../components/Chart"
import Typewriter from "../components/Typewriter"
import BoxAnimation from "../components/BoxAnimation"

import LogoLp from "../icons/logo-lp.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const IndexPage = (props) => (
  <Layout
    location={props.location}
    title="Online Kreativagentur"
    description="lopezdesign ist eine online Kreativagentur für Webseiten. Ich entwerfe und entwickle Ihre professionelle, elegante und moderne Webseite."
  >
    <Block color="white"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h1>
            <div className="shine">
              <span>lopezdesign</span><span>Web-Entwickler</span>
            </div>
            <span className="heading-4">lopezdesign.de</span>
            <a className="heading-4" href="mailto:mail@lopezdesign.de" title="E-Mail">E-Mail</a>
          </h1>
        </div>
      }
      right={
        <div className="grid__item width--0  width-desk--1of2">
          <div className="text--right">
            <LogoLp alt="lopezdesign - Logo" title="lopezdesign - Logo" />
          </div>
        </div>
      }
    />
    <Block color="colorCoral"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h2 className="text--big"><span>Elegant</span><span>Design</span></h2>
          <ul>
            <li>Web development</li>
            <li>UI & UX Design</li>
          </ul>
        </div>
      }
      right={
        <div className="grid__item width-desk--1of2">
          <Phone de />
        </div>
      }
    />
    <Block color="grayHell"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h2>Webdesign</h2>
          <p className="constrain--small">
            Lopez Design ist eine digitale Kreativagentur für Webdesign. Ich entwerfe und entwickeln Ihre professionelle,
            elegante und moderne Website.
            Mit responsivem Design und einer guten Umsetzung der Usability präsentieren Sie Ihre neue Website im Internet.
            Elegant und modern!
          </p>
        </div>
      }
      right={
        <div className="grid__item width-desk--1of2">
          <BoxAnimation boxClass="rotation">
            <StaticImage
              src="../images/webdesign.png"
              alt="Webdesign - lopezdesign"
              width={580}
              layout="fullWidth"
              placeholder={false}
            />
          </BoxAnimation>
        </div>
      }
    />
    <Block color="black"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h2>Web-Entwicklung</h2>
          <p className="constrain--small">
            Ich setze Ihr Webprojekt mit neuer Technologie um.
            Egal ob mit CMS wie Contao oder komplett von Hand programmiert, ich habe eine passende Lösung für Sie.
            Mit sauberem und optimiertem Code heben Sie Ihre Website mit höchster Leistung und Effizienz auf ein neues Niveau.
          </p>
        </div>
      }
      right={
        <div className="grid__item width-desk--1of2">
          <h2 className="text--big"><span>Coden</span><span>macht</span><span><Typewriter text={'Spaß!'} /></span></h2>
        </div>
      }
    />
    <Block color="colorCoral"
      left={
        <div className="grid__item width-desk--1of2 space--bottom-double">
          <h2>Optimiert!</h2>
          <p>Eine optimierte Website bringt nur Vorteile:</p>
          <ul className="text--small">
            <li>Kurze Ladezeit</li>
            <li>Schnellere Ladegeschwindigkeit der Website</li>
            <li>Einsparung von mobilem Datenvolumen</li>
            <li>Das Warten wird Ihnen nicht langweilig</li>
            <li>Sauberen Code generieren</li>
            <li>Verbesserung der Benutzerfreundlichkeit</li>
            <li>Erhöht die Benutzerfreundlichkeit</li>
            <li>Verbessern Sie SEO</li>
          </ul>
        </div>
      }
      right={
        <div className="grid__item width-desk--1of2">
          <Chart />
          <p className="text--small text--center">Quelle: Google Developers - Page Speed</p>
        </div>
      }
    />
    <Block color="white"
      left={
        <div className="grid__item width-desk--4of12 space--bottom-double">
          <h2>Top Creative</h2>
          <ul>
            <li>Webdesign</li>
            <li>CMS - Contao</li>
            <li>Web-Entwicklung</li>
          </ul>
        </div>
      }
      right={
        <div className="grid__item width-desk--8of12">
          <StaticImage
            className="maus-case"
            src="../images/maus-case.png"
            alt="Maus Projekt - lopezdesign"
            width={790}
            layout="fullWidth"
            placeholder={false}
          />
        </div>
      }
    />
  </Layout>
)

export default IndexPage
